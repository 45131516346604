/* You can add global styles to this file, and also import other style files */

html {
  overflow-x: hidden;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: #fff5f7;
  font-family: 'Poppins', sans-serif !important;
}

body .snow {
  overflow: hidden;
}

$theme-color: #cf7b7d;

$white-color: #fff;
$pink-btn-shade: #cf7b7d;

// Active and Discontinued Devices
$green-color-shade-one: #7cb342;
$red-color-shade-one: rgb(204, 66, 69);

.muted_text {
  color: #797979;
  font-family: math !important;
}

.blockquote-footer {
  font-weight: 500;
}

.home-page-img {
  color: $theme-color;
}

.elixir-phonecard {
  border: none;
}

.elixir-bannercard {
  border: none;
}

.container {
  // background: white;
  text-align: center;
}

.container-home {
  text-align: center;
  background-color: #ffe9e9;
  padding: 2rem;

}

.container-fliud {
  background-color: #fff5f7;
}

.marTB2 {
  margin: 4.5rem 0 2.5rem 0;
  font-size: 2.2rem;
  font-weight: 600;
}

.f_head {
  font-weight: 600;
  // margin-top: -5rem;
  font-size: 2.5rem;
}

.features_card {
  border: none !important;
  margin-top: 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 4px 32px 8px rgba(218, 218, 218, 0.25);
}

.Important-platforms {
  border: none !important;
  border-radius: 1.5rem;
  box-shadow: 0 4px 32px 8px rgba(218, 218, 218, 0.25);
  padding: 2rem 2.2rem 2rem 2.2rem;
  background-color: white;
}

.feature_img {
  padding: 2rem;
}

.device-btn {
  background-image: linear-gradient(to right, #cf7b7d 0%, #552f33 51%, #cf7b7d 100%);
  line-height: 2.5rem;
  width: -webkit-fill-available;
  text-align: center;
  letter-spacing: 1.1px;
  // text-transform: uppercase;
  font-weight: 600;
  transition: 0.3s;
  background-size: 200% auto;
  padding-left: 26px;
  padding-right: 26px;
  color: white;
  border-radius: 30px;
  margin-top: 2rem;
}

.device-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.footer-support-btn {
  width: auto;
  border-radius: 30px;
  background-size: 200% auto;
  // font-weight: 600;
  position: relative;
  transition: 0.3s;
  letter-spacing: 1.1px;
  line-height: 2.35rem;
  color: #ffffff;
  font-size: 15px;
  // text-transform: uppercase;
  display: inline-block;
  padding-left: 22px;
  padding-right: 22px;
  margin-top: 0.7rem;
  background-image: linear-gradient(to right, #cf7b7d 0%, #552f33 51%, #cf7b7d 100%);
}

.footer-support-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  // padding-left: 26px;
  // padding-right: 26px;
  text-decoration: none;
}

.archive-btn {
  background-image: linear-gradient(to right, #cf7b7d 0%, #552f33 51%, #cf7b7d 100%);
  line-height: 2.5rem;
  text-align: center;
  letter-spacing: 1.1px;
  // text-transform: uppercase;
  font-weight: 600;
  transition: 0.3s;
  background-size: 200% auto;
  padding-left: 26px;
  padding-right: 26px;
  color: white;
  border-radius: 30px;
  margin-top: 2rem;
}

.archive-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.shadow-light {
  box-shadow: 0 4px 32px 8px rgba(218, 218, 218, 0.25);
}

.support_card {
  padding: 2rem 1rem 2rem 1rem;
  text-align: center;
}

.support_line {
  margin-top: 1rem;
  font-size: 1.7rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
}

.support_sum {
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.marT5 {
  margin-top: 4rem;
}

.rounded {
  border-radius: 1.5rem !important;
}

.text-muted {
  color: #666666 !important;
}

// scroll to top
.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  opacity: 0;
  transition: all .2s ease-in-out;
}

.show-scrollTop {
  opacity: 1;
  transition: all .2s ease-in-out;
}

.scroll-btn,
.scroll-btn:hover {
  cursor: pointer;
  background: #cf7b7d;
  border-radius: 15rem;
  color: white;
}

// Ease in out Animation 
.website_animation {
  position: relative;
  margin-top: 4rem;
  animation-delay: 0.3s !important;
  animation: website_animation 1s linear forwards;
  animation-timing-function: ease-in-out;
}

@keyframes website_animation {
  from {
    top: 0px;
  }

  to {
    top: -50px;
  }
}

.cl_animation1 {
  position: relative;
  animation-delay: 0.3s !important;
  animation: cl_animation1 1s linear forwards;
  animation-timing-function: ease-in-out;
}

@keyframes cl_animation1 {
  from {
    bottom: -50px;
  }

  to {
    bottom: 0px;
  }
}

.patreon-btn {
  background-image: linear-gradient(to right, #cf7b7d 0%, #552f33 51%, #cf7b7d 100%);
  line-height: 2.5rem;
  display: block;
  text-align: center;
  // text-transform: uppercase;
  transition: 0.3s;
  letter-spacing: 1.1px;
  background-size: 200% auto;
  color: rgb(255, 255, 255);
  font-weight: 500;
  padding-left: 26px;
  padding-right: 26px;
  border-radius: 30px;
}

.patreon-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #ffffff;
  text-decoration: none !important;
}

.patreon_new {
  width: 15rem;
  margin-bottom: 2rem;
}

.patreon-card-right {
  margin-bottom: 2rem;
}

.patreon_new-cl {
  width: 15rem;
}

.bi-patreon {
  margin-right: 0.3rem;
}

.patreon_head {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
  color: rgb(0, 0, 0);
}

.patreon_highlight {
  color: rgb(0, 0, 0);
  font-size: 0.9rem;
}

.box-shadow {
  inset: 0px 0px 20px 0px rgb(179 179 179 / 25%) !important
}

.heart {
  margin-bottom: 0.7rem;
  animation: heartbeat 1s infinite;
}

.key-high {
  color: #cf7b7d;
}

.donate-footer-head-line {
  font-weight: 600;
}

.snow-text {
  max-width: 29rem;
  text-align: left;
  font-size: 2rem;
  margin-bottom: -0.3rem;
  // color: #cf7b7d;
}

.snow-highlight {
  font-size: 3rem;
  color: #cf7b7d;
  letter-spacing: 0.5rem;
}

/** Snow effect **/
// @function random_range($min, $max) {
//   $rand: random();
//   $random_range: $min + floor($rand * (($max - $min) + 1));
//   @return $random_range;
// }

// .snow {
//   $total: 200;
//   position: absolute;
//   width: 20px;
//   height: 20px;
//   font-size: 20px;
//   border-radius: 50%;
//   pointer-events: none;
//   color: #a3b1bc;

//   @for $i from 1 through $total {
//     $random-x: random(1000000) * 0.0001vw;
//     $random-offset: random_range(-100000, 100000) * 0.0001vw;
//     $random-x-end: $random-x + $random-offset;
//     $random-x-end-yoyo: $random-x + ($random-offset / 2);
//     $random-yoyo-time: random_range(30000, 80000) / 100000;
//     $random-yoyo-y: $random-yoyo-time * 100vh;
//     $random-scale: random(10000) * 0.0001;
//     $fall-duration: random_range(10, 30) * 1s;
//     $fall-delay: random(30) * -1s;

//     &:nth-child(#{$i}) {
//       opacity: random(8000) * 0.0001;
//       transform: translate($random-x, -10px) scale($random-scale);
//       animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
//     }

//     @keyframes fall-#{$i} {
//       #{percentage($random-yoyo-time)} {
//         transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
//       }

//       to {
//         transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
//       }
//     }
//   }
// }

@media (min-width: 320px) and (max-width: 768px) {

  .patreon_new {
    padding: 0.1rem;
    margin-top: 2rem;
    // padding-left: 0.5rem;
  }

  .features {
    font-size: 2rem;
    padding: 0 1rem 0 1rem;
    margin: 5.5rem 0 5.5rem 0;
  }

  .f_head {
    font-size: 1.7rem;
  }

  .donate-footer-head-line {
    font-size: 1.8rem;
  }

  .patreon-card-right {
    text-align: center;
    margin-bottom: 2rem;
  }
}